import { useEffect, useContext, useCallback, memo, useState } from 'react';
import { Spin, Button, Modal } from 'antd';
import { GlobalOutlined } from '@ant-design/icons';
import { AuthContext } from '@/model/AuthProvider/Context';
import { TabContext } from '@/model/TabProvider/Context';
import { MQTTContext } from '@/model/MQTTProvider/Context';
import cls from 'classnames';
import { OPEN_ID, TOKEN_KEY } from '@/lib/constants';
import { TAB_ITEM } from '@/types';
import { useIntl } from 'react-intl';
import { merge } from 'lodash';
import { checkIframeLoaded, isYoutubeLink, getCurrentState, feloDocUrlAddTeamId } from '@/utils/index';
import dynamic from 'next/dynamic';
import * as R from 'ramda';
import s from './style.module.less';
const Draw: any = dynamic(() => import('@/components/Preview/Viewer/Draw'), { ssr: false });
const TabContainer: any = dynamic(() => import('@/components/TabContainer'), { ssr: false });
const Intro: any = dynamic(() => import('@/components/Intro'), { ssr: false });
const OpenURL: any = dynamic(() => import('@/components/Preview/Viewer/OpenURL'), { ssr: false });
const Preview: any = dynamic(() => import('@/components/Preview'), { ssr: false });
const TemplateWrap: any = dynamic(() => import('@/components/TemplateWrap'), { ssr: false });
const YoutubePlayer: any = dynamic(() => import('@/components/Preview/Viewer/YoutubePlayer'), { ssr: false });

const LOCAL_TAB_TYPES = [2, 4, 7];

const renderNewTabLink = ({ url, openNewTabText }: { url: string; openNewTabText: string }) => {
  return (
    <div className={s.buttonDiv}>
      <Button
        onClick={(e) => {
          window.open(url, '_blank');
        }}
        icon={<GlobalOutlined />}
      >
        {openNewTabText || ''}
      </Button>
    </div>
  );
};

const Iframe = memo(({ url, domId }: { url: string; domId: string }) => {
  return (
    <div className={cls(s.iframeDiv)}>
      <iframe
        title={domId}
        id={domId}
        onLoad={() => {}}
        src={url}
        loading="eager"
        className={cls(s.iframeCon, 'preview-iframe')}
      ></iframe>
    </div>
  );
});

/**
 * showDownload: 在纯预览条件下不需要显示下载部分
 */
export const RenderContent = memo(
  (
    props: TAB_ITEM & {
      meetId: string;
      teamId: string;
      isActive: boolean;
      isCached: boolean;
      showDownload?: boolean;
      tabId: string;
      openNewTabText?: string;
    },
  ) => {
    const {
      id,
      type,
      other_url,
      meetId,
      teamId,
      isActive,
      showDownload,
      openNewTabText = '',
      is_embeddable,
      isCached,
    } = props;
    const [loading, setLoading] = useState(true);
    const domId = `iframe-${id}`;
    const urlWithId = other_url + `?embedMeetId=${meetId}`; // felo doc 需要加入 meetId 作为参数
    const isBlocked = is_embeddable === 2;

    useEffect(() => {
      if (type === 2 || type === 4) {
        checkIframeLoaded(setLoading);
      }
    }, [other_url, type]);

    const isYTLink = isYoutubeLink(other_url);

    if (!isActive && !isCached && !LOCAL_TAB_TYPES.includes(type)) return <></>;
    switch (type) {
      case 2:
      case 4:
        return (
          <>
            <Spin className="preview-spin" spinning={loading} />
            <Preview {...props} {...{ showDownload, loading, setLoading }} key={id} />
          </>
        );
      case 3:
        if (isYTLink) {
          return <YoutubePlayer {...props} />;
        }
        const formatUrl1 = feloDocUrlAddTeamId(other_url, teamId);
        return (
          <OpenURL {...props}>
            {isBlocked ? (
              renderNewTabLink({ url: formatUrl1, openNewTabText })
            ) : (
              <Iframe url={formatUrl1} domId={domId} />
            )}
          </OpenURL>
        );
      case 7:
        return <Draw roomId={meetId + '_' + id} tabId={String(id)} />;
      default:
        const formatUrl2 = feloDocUrlAddTeamId(urlWithId, teamId);
        return (
          <>
            {isBlocked ? (
              renderNewTabLink({ url: formatUrl2, openNewTabText })
            ) : (
              <Iframe url={formatUrl2} domId={domId} />
            )}
          </>
        );
    }
  },
  (prevProps, nextProps) => {
    // 这里移除 icon 再进行比较是因为获取连接类型文档 icon 是异步操作，会引起不必要的 re-render
    const tmp: any[] = [prevProps, nextProps].map(R.omit(['icon', 'title']));
    const isEqual: boolean = R.equals(tmp[0], tmp[1]);

    return isEqual;
  },
);

const Web = ({ roomId, tabId }: { roomId?: string; tabId?: string }) => {
  const {
    connectionStatus,
    client,
    baseConfig,
    syncStatus,
    setSyncStatus,
    setPptSyncStatus,
    setWhiteboardSyncStatus,
    setMarkerSyncStatus,
    setSyncMarkerVisible,
    setPdfSyncStatus,
  } = useContext(MQTTContext);

  const {
    activeTabId,
    items,
    orderItems,
    setOrderItems,
    fetchTabs,
    setActiveTabId,
    setRefreshTabId,
    syncActiveTab,
    youtubePlayers,
    mqttPubUpdateYT,
    cacheTabs,
  } = useContext(TabContext);
  const { state, meetId, teamId, updatePower, isDemoMode, isDemoHost } = useContext(AuthContext);
  const intl = useIntl();

  useEffect(() => {
    syncActiveTab();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetId]);

  const messageHandler = useCallback(
    (topic: string, message: string | Buffer) => {
      let msg = {};
      if (message) {
        try {
          msg = JSON.parse(message.toString());
        } catch (err) {
          console.log('message parse error: ', message.toString());
        }
      }
      const { type, payload, client_id, data, open_id } = msg as MQTT.PubMessage;

      // 只处理不是自己发出的消息
      if (baseConfig && topic === baseConfig.topic && client_id !== baseConfig.client_id) {
        switch (type) {
          case 'update_all':
            console.log('update_all');
            fetchTabs();
            break;
          case 'update_activeTab':
            if (syncStatus) {
              console.log('update_activeTab', payload);
              const pubActiveId = R.pathOr(0, ['activeTabId'], payload);
              // activeTabId 不同才更新
              if (pubActiveId && pubActiveId !== activeTabId) {
                setActiveTabId(pubActiveId);
              }
            }
            break;
          case 'reorder':
            if (syncStatus) {
              console.log('reorder', payload);
              const pubOrderItems = R.pathOr(null, ['orderItems'], payload);
              // orderItems 不同才更新
              if (
                pubOrderItems &&
                !R.equals((pubOrderItems as TAB_ITEM[]).map(R.prop('id')), orderItems.map(R.prop('id')))
              ) {
                setOrderItems(pubOrderItems);
              }
            }
            break;
          case 'role_update':
          case 'speaker_update':
            console.log('MQTT message: ', msg);
            updatePower();
            break;
          case 'doc_update_power':
            console.log('doc_update_power', data);
            const userOpenId = sessionStorage.getItem(OPEN_ID);
            // Felo Doc 文档所有者权限变更后，其他参与者同步更新
            if (!userOpenId || userOpenId !== open_id) {
              setRefreshTabId(data.doc_uuid);
            }
            break;
          case 'sync_status':
            console.log('sync_status', payload);
            if ('syncStatus' in payload) {
              const { syncStatus: newSyncStatus } = payload;
              setSyncStatus(newSyncStatus);
            }
            break;
          case 'sync_ppt': {
            console.log('sync_ppt', payload);
            const { syncPptStatus = {} as MQTT.PptSyncStatus } = payload;
            const { pptIndex = 0, tabId = 0 } = syncPptStatus;

            setPptSyncStatus({
              pptIndex,
              tabId,
            });
            break;
          }
          case 'sync_pdf': {
            // console.log('sync_pdf', payload);
            const { offsetTopRatio, domId } = payload as { offsetTopRatio: number; domId: string };
            setPdfSyncStatus({ offsetTopRatio, domId });
            break;
          }
          case 'sync_youtube': {
            console.log('sync_youtube', payload);
            const { playing, seconds, playerId } = payload as {
              muted: boolean;
              playing: number;
              quality: string;
              rate: number;
              seconds: number;
              volume: number;
              playerId: string;
            };

            const player = youtubePlayers[playerId];

            if (player && player.seekTo) {
              player.seekTo && player.seekTo(seconds);
              if (playing !== 2) {
                player.playVideo();
                const isNotStart = player.getPlayerState() === -1;
                if (isNotStart) {
                  Modal.confirm({
                    closable: false,
                    wrapClassName: 'modal-felo',
                    title: intl.formatMessage({ id: 'YT.tip' }),
                    content: intl.formatMessage({ id: 'YT.tip.content' }),
                    okText: intl.formatMessage({ id: 'Confirm' }),
                    okButtonProps: { type: 'link' },
                    icon: '',
                    cancelText: intl.formatMessage({ id: 'Cancel' }),
                    cancelButtonProps: { type: 'text' },
                    maskClosable: true,
                    onOk: () => {
                      player.playVideo();
                    },
                  });
                }
              } else {
                player.pauseVideo();
              }
            } else {
              console.log('player not ready');
            }

            break;
          }
          case 'get_youtube_progress': {
            console.log('get_youtube_progress', payload);
            const { playerId } = payload as { playerId: string };

            const player = youtubePlayers[playerId];

            if (player && player.seekTo && isDemoHost) {
              const state = getCurrentState(player);
              console.log('isDemoHost mqttPubUpdateYT progress', state);
              // 这个地方加上延时才生效，但是逻辑上已经是在发起请求用户 player 已经就绪的情况下发起，原因未知
              setTimeout(() => {
                mqttPubUpdateYT(merge(state, { playerId }));
              }, 500);
            } else {
              console.log('player not ready');
            }

            break;
          }
          case 'sync_felo_doc': {
            // felo doc 文档同步事件
            if (syncStatus) {
              // console.log('sync_felo_doc', payload);

              const { type, domId, tabId, scrollTop, scrollHeight } = payload as {
                type: 'click' | 'scroll';
                domId: string;
                tabId: string;
                scrollTop: number;
                scrollHeight: number;
              };
              const iframeDom = document.getElementById('iframe-' + tabId);
              const dom = (iframeDom as HTMLIFrameElement)?.contentDocument?.querySelector(`[data-id="${domId}"]`);

              if (iframeDom) {
                switch (type) {
                  case 'click':
                    if (dom) {
                      dom.scrollIntoView({ behavior: 'smooth' });
                    }
                    break;
                  case 'scroll':
                    const editorDom = (iframeDom as HTMLIFrameElement)?.contentDocument?.querySelector(
                      '.editor-container',
                    );
                    const nowScrollHeight = editorDom?.scrollHeight ?? 0;
                    const heightRatio = nowScrollHeight / scrollHeight;
                    const nowScrollTop = heightRatio * scrollTop;

                    editorDom?.scrollTo({
                      top: nowScrollTop,
                      behavior: 'smooth',
                    });
                    break;
                  default:
                    console.log('sync_felo_doc unknown type: ', type);
                    break;
                }
              }
            }
            break;
          }
          case 'sync_whiteboard': {
            setWhiteboardSyncStatus(payload);
            break;
          }
          case 'sync_marker': {
            setMarkerSyncStatus(payload);
            break;
          }
          case 'sync_marker_visible': {
            setSyncMarkerVisible(payload);
            break;
          }
          default:
            console.log('MQTT messageHandler unknown', type);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      fetchTabs,
      baseConfig,
      activeTabId,
      orderItems,
      syncStatus,
      updatePower,
      setActiveTabId,
      setOrderItems,
      youtubePlayers,
      isDemoHost,
    ],
  );

  useEffect(() => {
    if (connectionStatus && client) {
      // 连接状态变更，刷新监听事件
      client.removeAllListeners('message');
      client.on('message', messageHandler);
    }
    return () => {
      if (client) {
        client.removeAllListeners('message');
      }
    };
  }, [connectionStatus, client, messageHandler]);

  if (!meetId) return <></>;

  if (state.role_list.read !== 1)
    return <div style={{ textAlign: 'center' }}>{intl.formatMessage({ id: 'noRightsVisit' })}</div>;

  const isHome = activeTabId === 0;

  return roomId && tabId ? (
    <Draw roomId={roomId} tabId={tabId} />
  ) : (
    <div
      className={cls(
        s.main,
        isDemoMode && s.mainWithDemoMode,
        isHome && s.homeMain,
        isHome && isDemoMode && s.homeMainWithDemoMode,
      )}
    >
      <TabContainer />

      <Intro style={{ display: isHome ? 'block' : 'none' }} />

      <TemplateWrap style={{ display: isHome ? 'block' : 'none' }} />

      {/* 保持渲染内容所以只是样式隐藏显示与否 */}

      {items.map((v) => (
        <div
          key={v.id}
          className={cls(s.contentCon, isDemoMode && 'main-demo-mode-content', activeTabId === v.id && s.activeContent)}
        >
          <RenderContent
            {...(v as TAB_ITEM)}
            isActive={activeTabId === v.id}
            isCached={cacheTabs.includes(v.id)}
            tabId={String(v.id)}
            meetId={meetId}
            teamId={teamId}
            openNewTabText={intl.formatMessage({ id: 'openNewTab' })}
          />
        </div>
      ))}
    </div>
  );
};

export default Web;
